.pesquisaDeSatisfacao_Respostas_botaoDeAdd_lchjvrewqy {
    color: #fff !important;
    background: #3bb037 !important;
    border-color: #3bb037 !important;
    background-color: #3bb037 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
}

.pesquisaDeSatisfacao_Respostas_botaoDeAdd_lchjvrewqy:hover {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}

.pesquisaDeSatisfacao_Respostas_botaoDeAdd_lchjvrewqy:active {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}
.pesquisaDeSatisfacao_Respostas_botaoDeAdd_lchjvrewqy:focus {
    background: #54c550 !important;
    background-color: #54c550 !important;
    border-color: #54c550 !important;
}
