.botaoEditar_Perguntas_kjhevfawehfg {
    width: 90px;
    color: #fff;
    background: #e08e0b;
    border-color: #e08e0b;
    background-color: #e08e0b;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}
.botaoEditar_Perguntas_kjhevfawehfg:hover {
    background: #F99E0E;
    background-color: #F99E0E;
    border-color: #F99E0E;
}
.botaoEditar_Perguntas_kjhevfawehfg:active {
    background: #F99E0E;
    background-color: #F99E0E;
    border-color: #F99E0E;
}
.botaoEditar_Perguntas_kjhevfawehfg:focus {
    background: #F99E0E;
    background-color: #F99E0E;
    border-color: #F99E0E;
}

.modalPergunta_ljfbelkfbjhvbrgf {
    width: 600px !important;
}

/* Modal de pergunta */
@media only screen and (min-width: 200px) and (max-width: 576px) {
    .modalPergunta_ljfbelkfbjhvbrgf {
        width: 400px !important;
    }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .modalPergunta_ljfbelkfbjhvbrgf {
        width: 600px !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .modalPergunta_ljfbelkfbjhvbrgf {
        width: 600px !important;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .modalPergunta_ljfbelkfbjhvbrgf {
        width: 600px !important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .modalPergunta_ljfbelkfbjhvbrgf {
        width: 700px !important;
    }
}
@media only screen and (min-width: 1600px) {
    .modalPergunta_ljfbelkfbjhvbrgf {
        width: 700px !important;
    }
}
