.botaoAdicionarIntegracao_kjhsdvfckahwevoik {
    color: #fff;
    background: #3bb037;
    border-color: #3bb037;
    background-color: #3bb037;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.botaoAdicionarIntegracao_kjhsdvfckahwevoik:hover {
    background: #54c550;
    background-color: #54c550;
    border-color: #54c550;
}

.botaoAdicionarIntegracao_kjhsdvfckahwevoik:active {
    background: #54c550;
    background-color: #54c550;
    border-color: #54c550;
}
.botaoAdicionarIntegracao_kjhsdvfckahwevoik:focus {
    background: #54c550;
    background-color: #54c550;
    border-color: #54c550;
}
