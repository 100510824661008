/* #fa8c16 */
.botaoGestorMunicipiosPorContrato {
    color: #fff;
    background: #fa8c16 !important;
    border-color: #fa8c16 !important;
    background-color: #fa8c16 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.botaoGestorMunicipiosPorContrato:hover {
    background: #f59d3e !important;
    background-color: #f59d3e !important;
    border-color: #f59d3e !important;
}

.botaoGestorMunicipiosPorContrato:active {
    background: #f59d3e !important;
    background-color: #f59d3e !important;
    border-color: #f59d3e !important;
}
.botaoGestorMunicipiosPorContrato:focus {
    background: #f59d3e !important;
    background-color: #f59d3e !important;
    border-color: #f59d3e !important;
}


@media only screen and (min-width: 200px) and (max-width: 576px) {
    .municipiosContratto_wtResponsivo_uyvfqewowefyufv {
        width: 94%;
    }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .municipiosContratto_wtResponsivo_uyvfqewowefyufv {
        width: 97%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .municipiosContratto_wtResponsivo_uyvfqewowefyufv {
        width: 60%;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .municipiosContratto_wtResponsivo_uyvfqewowefyufv {
        width: 70%;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .municipiosContratto_wtResponsivo_uyvfqewowefyufv {
        width: 75%;
    }
}
@media only screen and (min-width: 1600px) {
    .municipiosContratto_wtResponsivo_uyvfqewowefyufv {
        width: 75%;
    }
}
