.divNameCongSistemaList {
    margin-right: -35px;
}

.divCollapses {
    margin-bottom: 20px;
}

/* botoes de deletar esta na lisgaem de configuracoes do sistema */
.botaoDeleteAssociacao {
    margin-left: 10px;
}

@media only screen and (min-width: 399px) and (max-width: 433px) {
    .botaoDeleteAssociacao {
        margin-left: 5px;
    }
}

@media only screen and (max-width: 399px) {

    .botaoDeleteAssociacao {
        margin-left: 10px;
        width: 80px;
    }
}

/*para o modal da entidade*/

@media only screen and (min-width: 200px) and (max-width: 576px) {
    .modalNotificacaoAppEntidade {
        width: 400px;
    }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .modalNotificacaoAppEntidade {
        width: 400px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .modalNotificacaoAppEntidade {
        width: 600px !important;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .modalNotificacaoAppEntidade {
        width: 600px !important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .modalNotificacaoAppEntidade {
        width: 600px !important;
    }
}
@media only screen and (min-width: 1600px) {
    .modalNotificacaoAppEntidade {
        width: 600px !important;
    }
}

