.dividerPermisaoDoApp {
    border-top: 1px solid #1890ff;
}

@media only screen and (min-width: 200px) and (max-width: 576px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 10%;
    }
    .botaoRemoverAditivosProrrogacaoContrato {
        margin-left: 40%;
    }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 48%;
    }
    .botaoRemoverAditivosProrrogacaoContrato {
        margin-left: 58%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 39%;
    }
    .botaoRemoverAditivosProrrogacaoContrato {
        margin-left: 49%;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 20%;
    }
    .botaoRemoverAditivosProrrogacaoContrato {
        margin-left: 30%;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 50%;
    }
    .botaoRemoverAditivosProrrogacaoContrato {
        margin-left: 60%;
    }
}
@media only screen and (min-width: 1600px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 60%;
    }
    .botaoRemoverAditivosProrrogacaoContrato {
        margin-left: 70%;
    }
}
