
.normal {
  font-family: Georgia, sans-serif;
  margin-top: 4em;
  text-align: center;
  background: #FF0;
}

.welcome {
  height: 512px;
  background-size: 512px 512px;
}

.list {
  font-size: 1.2em;
  margin: 1.8em 0 0;
  list-style: none;
  line-height: 1.5em;
}

.list code {
  background: #f7f7f7;
}
