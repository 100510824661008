.tabelaAssociacaoAdd {
    margin-bottom: 10px;
}

@media only screen and (min-width: 200px) and (max-width: 576px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 10%;
    }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 48%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 39%;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 20%;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 50%;
    }
}
@media only screen and (min-width: 1600px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 60%;
    }
}
