.logoimgMenuResponsiveLogin {
    width: 300px;
    margin: 20px;
}

.fotofundoLogin {
    background: url('../../assets/fundoLogin.jpeg');
    width: auto;
    height: 100vh;
    background-size: cover;
}

@media only screen and (max-width: 700px) {
    .logoimgMenuResponsiveLogin {
        width: 200px;
        margin: 20px;
    }
}

@media only screen and (max-width: 576px) {
    .fotofundoLogin {
        display: none;
    }
}

@media only screen and (max-width: 410px) {
    .logoimgMenuResponsiveLogin {
        width: 200px;
        margin-left: -35px;
    }
    .login-form-forgot {
        margin-left: -35px !important;
    }
}
