.botaoAdicionarOrgaos_fioljbweofgbvo {
    color: #fff;
    background: #3bb037;
    border-color: #3bb037;
    background-color: #3bb037;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.botaoAdicionarOrgaos_fioljbweofgbvo:hover {
    background: #54c550;
    background-color: #54c550;
    border-color: #54c550;
}

.botaoAdicionarOrgaos_fioljbweofgbvo:active {
    background: #54c550;
    background-color: #54c550;
    border-color: #54c550;
}
.botaoAdicionarOrgaos_fioljbweofgbvo:focus {
    background: #54c550;
    background-color: #54c550;
    border-color: #54c550;
}
