.dividerPermisaoDoApp {
    border-top: 1px solid #1890ff;
}

@media only screen and (min-width: 200px) and (max-width: 576px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 10%;
    }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 48%;
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 39%;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 20%;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 50%;
    }
}
@media only screen and (min-width: 1600px) {
    .botaoRemoverCidadesContratoAll {
        margin-left: 60%;
    }
}

/* Adicione essas classes no seu arquivo CSS ou na tag <style> do seu componente */
.image-container {
    position: relative;
    display: inline-block;
    margin-right: 10px;
  }
  
  .image {
    width: 128px;
    border-radius: 5px;
    margin: 5px;
  }
  
  .image-icons {
    position: absolute;
    top: 0;
    right: 0;
    display: none;
  }
  
  .image-container:hover .image-icons {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    margin: 5px;
    padding-left: 5px;
    padding-right: 5px;
    /* conteúdos no centro */
    display: flex;
    justify-content: center;
    align-items: center;
    /* margemEntre os objetos*/
    gap: 20px;
    transition: all 0.5s;

  }
  
  .view-icon,
  .edit-icon,
  .download-icon {
    font-size: 20px;
    color: #fff;
    display: inline-block;
    cursor: pointer;
  }

    .view-icon:hover,
    .edit-icon:hover,
    .download-icon:hover {
        color: #1890ff;
    }
  
  .membro-upload:hover{
    transition: 0.2s;
    cursor: pointer;
    filter: brightness(0.8);
}