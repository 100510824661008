.imgRelUltimoAcesso_fewhljbv {
    display: none;
}

.selectRelUltimoAcess_vwqeralhvjbwaw {
    min-width: 250px;
}

.colSelectRelUltimosAcessos_dewqhfvqoowef {
    display: flex;
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 15px;
}

.layoutStyledSelectandFilterUltimoAcesso_cwrejhfvbwaou {
    border-radius: 8px;
    margin: 5px;
    display: flex;
    justify-content: center;
    padding: 2px;
}

.divTable_Count_top {
    padding: 10px;
    margin: -8px;
    margin-bottom: 0.5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.divTable_Count_bottom {
    text-align: end;
}

@media print {

    html,
    body {
        height: auto !important;
        margin: 0 !important;
        padding: 0 !important;
        /* width: 210mm !important; */
        /* padding: 0px 15px 15px 15px !important; */
        overflow: hidden !important;
        -webkit-print-color-adjust: exact !important;
        background-color: #FFF !important;
        /* width: 1570px !important; */
        /* margin: 25mm 25mm 25mm 25mm; */
    }

    @page {
        size: A4 !important;
        margin: 0 !important;
        margin-top: 10mm !important;
        margin-bottom: 12mm !important;
        margin-left: 7mm !important;
        margin-right: 7mm !important;
        /* Define o padding/margem superior para cada página */
    }

    .ant-table-cell {
        color: #000 !important;
    }

    :where(.css-dev-only-do-not-override-1wux801).ant-table-wrapper .ant-table-thead>tr>th,
    :where(.css-dev-only-do-not-override-1wux801).ant-table-wrapper .ant-table-thead>tr>td {
        background-color: #e4e4e4 !important;
        color: #000 !important;
    }

    :where(.css-dev-only-do-not-override-1wux801).ant-table-wrapper .ant-table {
        background-color: #fff !important;
    }

    .ant-typography:not(.color-danger) {
        color: #000 !important;
    }

    /* Fundo da tabela */
    div>div>div>div.ant-table-wrapper>div>div>div {
        background-color: #fff !important;
        border: 0px !important;
    }


    .ant-typography:not(.text_valorEstimado_hjlbcveqwov) {
        font-size: 7.5px !important;
    }

    div>.ant-typography {
        font-size: 7.5px !important;
    }

    .ant-table-cell {
        background-color: #FFF !important;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        /* --antd-wave-shadow-color: #1890ff;
        --scroll-bar: 0; */
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        color: rgba(0, 0, 0, 0.85);
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        font-size: 7.5px;
        text-align: left;
        border-collapse: separate;
        border-spacing: 0;
        box-sizing: border-box;
        border-color: #f0f2f5 !important;
    }

    .ant-table-body {
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        --antd-wave-shadow-color: #1890ff;
        --scroll-bar: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        color: rgba(0, 0, 0, 0.85);
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        font-size: 7.5px;
        text-align: left;
        border-collapse: separate;
        border-spacing: 0;
        box-sizing: border-box;
    }

    .ant-table-footer {
        background-color: #FAFAFA !important;
    }

    .ant-table-title {
        background-color: #FAFAFA !important;
    }

    .ant-select-selector {
        -webkit-text-size-adjust: 100% !important;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0) !important;
        --antd-wave-shadow-color: #1890ff !important;
        --scroll-bar: 0 !important;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased !important;
        color: rgba(0, 0, 0, 0.85) !important;
        font-size: 7.5px !important;
        font-variant: tabular-nums !important;
        line-height: 1.5715px !important;
        list-style: none !important;
        font-feature-settings: 'tnum', "tnum" !important;
        box-sizing: border-box !important;
        display: flex !important;
        width: 100% !important;
        height: 18px !important;
        padding: 0 2px !important;
        position: relative !important;
        background-color: #fff !important;
        border: 1px solid #d9d9d9 !important;
        border-radius: 2px !important;
        transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1) !important;
        cursor: text !important;
        align-items: center !important;
        justify-content: center !important;
    }

    .ant-tag {
        font-size: 6.5px !important;
        line-height: 10px !important;
        padding: 2px !important;
        color: #000000e0 !important;
        border: 1px solid #d9d9d9 !important;
        background: #fafafa !important;
        white-space: inherit !important;
        box-sizing: border-box !important;
        margin: 0 !important;
        border-color: #d9d9d9 !important;
    }

    .ant-select-multiple .ant-select-selection-item {
        border: 1px solid #d9d9d9 !important;
        background: #fafafa !important;
    }

    span>.ant-tag {
        font-size: 7px !important;
        white-space: inherit !important;
        border-color: #d9d9d9 !important;
    }

    .ant-table-container {
        background-color: #FFF !important;
        -webkit-text-size-adjust: 100%;
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        --antd-wave-shadow-color: #1890ff;
        --scroll-bar: 0;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        -webkit-font-smoothing: antialiased;
        color: rgba(0, 0, 0, 0.85);
        font-variant: tabular-nums;
        line-height: 1.5715;
        list-style: none;
        font-feature-settings: 'tnum', "tnum";
        font-size: 14px;
        box-sizing: border-box;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        position: relative;
    }

    div.ant-table-container>div>table>thead>tr>th>div>span.ant-dropdown-trigger.ant-table-filter-trigger>span>svg {
        font-size: 7px !important;
    }

    div.ant-table-container>div>table>thead>tr>th>div>span.ant-table-column-title>div>span.ant-table-column-sorter.ant-table-column-sorter-full>span>span.anticon.anticon-caret-up.ant-table-column-sorter-up>svg {
        font-size: 7px !important;
    }

    div.ant-table-container>div>table>thead>tr>th>div>span.ant-table-column-title>div>span.ant-table-column-sorter.ant-table-column-sorter-full>span>span.anticon.anticon-caret-down.ant-table-column-sorter-down>svg {
        font-size: 7px !important;
    }


    .ant-select {
        font-size: 7.5px !important;
    }

    /* div > section > div > div.ant-row > div > div > div.selectRelUltimoAcess_vwqeralhvjbwaw > div > span > span > svg */
    .selectRelUltimoAcess_vwqeralhvjbwaw>div>span>span>svg {
        font-size: 7px !important;
    }

    .ant-pagination {
        display: none !important;
    }

    /*
        Classes da pagina
    */

    .imgRelUltimoAcesso_fewhljbv {
        display: block !important;
    }


    .tableRelUltimoAcesso_fwelhvfweoaufyv table {
        width: auto !important;
        font-size: 7.5px;
    }

    .tableRelUltimoAcesso_fwelhvfweoaufyv>div>div>div>div>div>table>tbody>tr.ant-table-placeholder>td>div {
        background-color: #fff !important;
        color: #000 !important;
    }

    .tableRelUltimoAcesso_fwelhvfweoaufyv>div>div>div>div>div>table>tfoot {
        background-color: #fff !important;
        color: #000 !important;
    }

    .tableRelUltimoAcesso_fwelhvfweoaufyv.css-dev-only-do-not-override-1wux801>div>div>div>div>div>table>tbody>tr {
        background-color: #e4e4e4 !important;
        color: #000 !important;
        /* display: none; */
    }

    .tableRelUltimoAcesso_fwelhvfweoaufyv>div>div>div>div>div>table>tbody>tr.ant-table-placeholder>td>div {
        width: 180mm !important;
        position: sticky;
        left: 0px;
        overflow: hidden;
    }

    .tableRelUltimoAcesso_fwelhvfweoaufyv>div>div>div>div>div>table>tbody>tr.ant-table-placeholder>td>div::after {
        position: absolute;
        top: 0;
        inset-inline-end: 1px;
        bottom: 0;
        border-inline-end: 0px solid #4c4c4c !important;
        content: "";
    }

    .tableRelUltimoAcesso_fwelhvfweoaufyv.css-1wux801>div>div>div>div>div>table>thead>tr>th {
        background-color: #E4E4E4 !important;
        color: #000 !important;
    }

    .tableRelUltimoAcesso_fwelhvfweoaufyv.css-1wux801>div>div>div>div>div>table>tbody>tr>td {
        background-color: #fff !important;
        color: #000 !important;
    }

    .selectRelUltimoAcess_vwqeralhvjbwaw {
        min-width: 100px !important;
        margin-top: -5px !important;
        margin-bottom: 5px !important;
    }

    .layout_UltimoAcesso_cvrehjvfqw {
        background-color: #fff !important;
    }

    .divTable_UltimoAcesso_awywfgbowreag {
        background-color: #FAFAFA !important;
    }

    .site-layout-background {
        background: #FFF !important;
        background-color: #FFF !important;
    }

    .layoutVGrey_UltimoAcesso_lvbweqrafw {
        background-color: #f0f2f5 !important;
    }

    .divImgGovFacilRel_UltimoAcesso_fewkhgfvqof {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }

    .btnIconMoreModal_hjebwgfvouab {
        font-size: 8px !important;
        height: 22px !important;
        width: 18px !important;
        display: flex !important;
        flex-direction: row !important;
        justify-content: center !important;
        align-items: center !important;
    }

    /* especifico */
    .tableRelUltimoAcesso_fwelhvfweoaufyv>div>div>div>div.ant-table-container>div>table>thead>tr>th:nth-child(5) {
        display: none !important;
    }

    .rowAcoes_UltimoAcesso_lcbqweuiwegcv {
        display: none !important;
    }

    .divRemoverDoRelatorio_dewhfvqwuoweg {
        display: none !important;
    }

    .titleSelectRelUltimosAcessos_cewlfhjcvbqweofbv {
        /* margin-bottom: -7px !important; */
    }

    .layoutStyledSelectandFilterUltimoAcesso_cwrejhfvbwaou {
        border-radius: 8px;
        margin: 5px;
        display: flex;
        justify-content: center;
        padding: 2px;
    }

    .colSelectRelUltimosAcessos_dewqhfvqoowef {
        display: flex;
        margin-top: 5px;
        margin-bottom: 5px;
        margin-left: 5px;
        margin-right: 5px !important;
    }

    .divTable_Count_top {
        padding: 0px !important;
        margin: 0px !important;
        margin-bottom: 0px !important;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 8px !important;
    }

    .divTable_Count_bottom {
        display: flex;
        justify-content: end;
        align-items: center;
        height: 8px !important;
    }
}


@media only screen and (min-width: 200px) and (max-width: 576px) {
    .modalRelModulosPorUsuarios {
        width: 97% !important;
    }
}
@media only screen and (min-width: 576px) and (max-width: 768px) {
    .modalRelModulosPorUsuarios {
        width: 95% !important;
    }
}
@media only screen and (min-width: 768px) and (max-width: 992px) {
    .modalRelModulosPorUsuarios {
        width: 90% !important;
    }
}
@media only screen and (min-width: 992px) and (max-width: 1200px) {
    .modalRelModulosPorUsuarios {
        width: 80% !important;
    }
}
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    .modalRelModulosPorUsuarios {
        width: 70% !important;
    }
}
@media only screen and (min-width: 1600px) {
    .modalRelModulosPorUsuarios {
        width: 60% !important;
    }
}
