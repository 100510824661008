.botaoVerde_Contrato_dxwsjhvdcqwiytfc {
    color: #fff;
    background: #3bb037;
    border-color: #3bb037;
    background-color: #3bb037;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.botaoVerde_Contrato_dxwsjhvdcqwiytfc:hover {
    background: #54c550;
    background-color: #54c550;
    border-color: #54c550;
}

.botaoVerde_Contrato_dxwsjhvdcqwiytfc:active {
    background: #54c550;
    background-color: #54c550;
    border-color: #54c550;
}

.botaoVerde_Contrato_dxwsjhvdcqwiytfc:focus {
    background: #54c550;
    background-color: #54c550;
    border-color: #54c550;
}

@media only screen and (min-width: 200px) and (max-width: 576px) {
    .botaoVerde_Contrato_dxwsjhvdcqwiytfc {
        margin-top: 5px !important;
    }
}

@media only screen and (min-width: 576px) and (max-width: 768px) {
    .botaoVerde_Contrato_dxwsjhvdcqwiytfc {
        margin-top: 5px !important;
    }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
    .botaoVerde_Contrato_dxwsjhvdcqwiytfc {
        margin-top: 5px !important;
    }
}
